.mi-avatar {
  img {
    -webkit-border-radius: 50%;
    -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.06);
    -webkit-transition: -webkit-box-shadow 0.3s ease;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.06);
    max-width: 100%;
    transition: box-shadow 0.3s ease;
    width: 120px;
  }
  img:hover {
    -webkit-box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.1);
  }
}
